<section style="height: 100vh;">
    <div class="container" style="padding-top: 8rem;">
        <div class="row">
            <div class="col-md-4 mx-auto p-4" style="border-radius: 1rem; background: #663399;">
                <h3 class="text-center" style="color: white;">Choose Healing</h3>
                <div class="vstack gap-3 pt-3">
                    <a *ngFor="let service of serviceList" [routerLink]="['/healing-detail']" [state]="{country_id:activeCountryId,service_id:service.service_id}">
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">{{service.name}} ({{service.time}}) - {{service.charge | currency:service.charge_currency_code}}</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
