import { Component } from '@angular/core';

@Component({
  selector: 'app-page-suggestion',
  templateUrl: './page-suggestion.component.html',
  styleUrl: './page-suggestion.component.scss'
})
export class PageSuggestionComponent {

}
