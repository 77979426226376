<!-- Type1 Healing -->
<section *ngIf="healingType==1" style="padding-top: 7rem;">
    <form action="">
        <div class="container p-4" style="border-radius: 1rem; background: #663399;">
            <h3 class="text-center" style="color: white;">Healing For</h3>
            <div class="row pt-2 justify-content-center">
                <div *ngFor="let healingFor of healingForList" class="col-3 py-2">
                    <button type="button" class="rounded-pill btn-inactive" [class.btn-inactive]="!healingFor.selected" style="border: 0; height: 3rem; width: 100%;" (click)="toggleHealingFor(healingFor.name)">{{healingFor.name}}</button>
                </div>
            </div>
        </div>

        <div class="container p-4" style="width: 50vw;">
            <div class="vstack p-4" style="border-radius: 1rem; background: #663399;color: white;">
                <h3 class="text-center">More Details, If any</h3>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Period of Discomfort</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="3 days" [(ngModel)]="periodOfDiscomfort">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput2">Current Treatments, If any</label>
                    <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="Allopathy" [(ngModel)]="typeOfTreatment">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Past Treatments, If any</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Share your past treatment information here" [(ngModel)]="pastTreatment"></textarea>
                  </div>    
            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <a [routerLink]="['/healing-timeslot']" class="text-center">
                    <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
                </a>
            </div>
        </div>
    </form>
</section>

<!-- Type2 Healing -->
<section *ngIf="healingType==2" style="padding-top: 7rem;">
    
</section>

<!-- Type3 Healing -->
<section *ngIf="healingType==3" style="padding-top: 7rem;">
    
</section>