import { Component } from '@angular/core';

@Component({
  selector: 'app-page-profile-detail',
  templateUrl: './page-profile-detail.component.html',
  styleUrls: ['./page-profile-detail.component.scss']
})
export class PageProfileDetailComponent {

}
