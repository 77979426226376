<section style="padding-top: 7rem;">
    <form action="">
        <div class="container p-4" style="width: 50vw;">
            <div class="vstack p-4" style="border-radius: 1rem; background: #663399;color: white;">
                <h3 class="text-center">Healing Order Confirmed</h3>
                <p class="mt-2">
                    Hi,<br>
                    Your Healing is booked, you will receive an email confirmation before and after healing.
                </p>
                <div class="card" style="border-radius: 2rem;">
                    <div class="card-body">
                        <div class="card-body">
                            <h5 class="card-title text-center">NOTICE</h5>
                            <p class="card-text text-center"> IT IS PREFERABLE TO SIT IN A RECEPTIVE POSTURE AT THE SELECTED TIME PERIOD OF YOUR HEALING.</p>
                        </div>
                        <div class="text-center">
                            <img  width="300px" class="img-fluid" src="../../assets/image/posture.png" alt="Card image cap">
                        </div>
                        
                    </div>
                </div>
                <p class="text-center">To know more about Pranic Healing visit:<br><a style="color: white;" href="https://www.worldpranichealing.com">https://www.worldpranichealing.com/</a></p>
                <H5 class="text-center">Thank You</H5>

            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <a [routerLink]="['/']" class="text-center">
                    <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Go Back</button>
                </a>
            </div>
        </div>
    </form>
</section>