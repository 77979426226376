import { Component } from '@angular/core';

@Component({
  selector: 'app-page-email-otp',
  templateUrl: './page-email-otp.component.html',
  styleUrls: ['./page-email-otp.component.scss']
})
export class PageEmailOtpComponent {

}
