import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '../guard/auth.guard';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,private authGuard:AuthGuard) { }

  // ------------------------------------------- public api ---------------------------------------
  countryList(){
    let body={
      action:'country', 
      data: null
    };
    return this.http.post<any>(`${environment.apiUrl}/info`,body);
  }

  serviceList(){
    let body={
      action:'service', 
      data: null
    };
    return this.http.post<any>(`${environment.apiUrl}/info`,body);
  }

  healingForList(){
    let body={
      action:'healing_for', 
      data: null
    };
    return this.http.post<any>(`${environment.apiUrl}/info`,body);
  }

  getHealingNo(countryId:number,serviceId:number){
    let body={
      action:'get_healing_no', 
      data: {
        service_id:serviceId,
        country_id:countryId
      }
    };
    return this.http.post<any>(`${environment.apiUrl}/healing`,body);
  }

  updateHealingType1Detail(healingNo:number,healingFor:string,periodOfDiscomfort:string,typeOfTreatment:string, pastTreatment:string){
    let body={
      action:'healing_type1_detail_update', 
      data: {
        healing_no:healingNo,
        healing_for:healingFor,
        period_of_discomfort:periodOfDiscomfort,
        type_of_treatment:typeOfTreatment,
        past_treatments:pastTreatment
      }
    };
    return this.http.post<any>(`${environment.apiUrl}/healing`,body);
  }

  // ------------------------------------------- logged-in api ---------------------------------------
}
