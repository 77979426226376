import { Component } from '@angular/core';

@Component({
  selector: 'app-page-notfound',
  standalone: true,
  imports: [],
  templateUrl: './page-notfound.component.html',
  styleUrl: './page-notfound.component.scss'
})
export class PageNotfoundComponent {

}
