<!-- sidebar -->
<div class="row">
    <div class="col-1 d-flex justify-content-center">
        <div id="mySidenav" class="sidenav"  [class.sidenav-show]="toggleFlag">
            <app-layout-sidebar></app-layout-sidebar>
        </div>
    </div>
</div>

<!--Navbar-->
<nav class="navbar navbar-dark bg-dark" style="box-shadow: 0px 0px 8px #8037b7;">
    <!-- Collapse button -->
    <button class="navbar-toggler hamburger-button" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation" (click)="sidebarToggle();" style="z-index: 2">
        <div class="animated-icon" [class.open]="toggleFlag"><span></span><span></span><span></span></div>
    </button>
    <!-- Navbar brand -->
    <!-- <div class="mx-auto order-0">
        <a class="navbar-brand mx-auto" href="#">center</a>
    </div> -->
    <app-layout-header></app-layout-header>
</nav>
<!--/.Navbar-->

<router-outlet ></router-outlet>
<app-layout-footer></app-layout-footer>

