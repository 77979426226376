import { Component, ElementRef, Renderer2, ViewChild, viewChild } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  toggleFlag: boolean = false;

  @ViewChild('mySidenav') sidebarDiv:ElementRef | undefined;

  constructor(private renderer: Renderer2){}

  
  public sidebarToggle(){
    this.toggleFlag=!this.toggleFlag;
    // if(this.toggleFlag){
    //   this.renderer.setStyle(this.sidebarDiv?.nativeElement,'width','250px')
    // }else{
    //   this.renderer.setStyle(this.sidebarDiv?.nativeElement,'width','0px')
    // }
  }
}


// <script>
//     function Nav() {
//   var width = document.getElementById("mySidenav").style.width;
//   if (width === "0px" || width == "") {
//     document.getElementById("mySidenav").style.width = "250px";
//     $('.animated-icon').toggleClass('open');
//   }
//   else {
//     document.getElementById("mySidenav").style.width = "0px";
//     $('.animated-icon').toggleClass('open');
//   }
// }
// </script>