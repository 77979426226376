import { Component } from '@angular/core';

@Component({
  selector: 'app-page-payment',
  templateUrl: './page-payment.component.html',
  styleUrl: './page-payment.component.scss'
})
export class PagePaymentComponent {

}
