<section style="padding-top: 7rem;">
    <form action="">
        <div class="container p-4" style="width: 50vw;">
            <div class="vstack p-4" style="border-radius: 1rem; background: #663399;color: white;">
                <h3 class="text-center">Profile</h3>
                <a [routerLink]="['/login']" class="text-center" style="color: white;">If you already have a account, Login now.</a>
                <div class="row">
                    <div class="col">
                        <i class="bi bi-person-fill" style="font-size: 200px;margin: 0;padding: 0;">
                            <div class="form-group" style="font-size: 1rem;">
                                <label for="exampleFormControlFile1">Select Photo</label>
                                <input type="file" class="form-control-file" id="exampleFormControlFile1">
                            </div>
                        </i>
            
                    </div>
                    <div class="col" style="padding-top: 6rem;">
                        <div class="py-2 px-4">
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">Male</button>
                        </div>
                        <div class="py-2 px-4">
                                <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">Female</button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Full Name</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput2">Date of Birth</label>
                    <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Full Address</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder=""></textarea>
                </div>   
                <div class="form-group">
                    <label for="exampleFormControlInput3">Pincode</label>
                    <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="">
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput4">Country</label>
                    <input type="text" class="form-control" id="exampleFormControlInput4" placeholder="">
                </div> 
                <div class="form-group">
                    <label for="exampleFormControlInput5">Mobile</label>
                    <input type="text" class="form-control" id="exampleFormControlInput5" placeholder="">
                </div> 
                <div class="form-group">
                    <label for="exampleFormControlInput6">Email</label>
                    <input type="email" class="form-control" id="exampleFormControlInput6" placeholder="">
                </div> 
            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <a [routerLink]="['/payment']" class="text-center">
                    <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
                </a>
            </div>
        </div>
    </form>
</section>