<!-- country select -->
<section style="padding-top: 8rem;padding-bottom: 5rem;">
    <h2 class="text-center">Healing the World</h2>
    <div class="row justify-content-center pt-4">
        <div class="col-lg-3 my-1">
            <select class="form-control rounded-pill" name="country" id="country" style="height: 3rem;font-size: 1rem;" (change)="onCountryChange($event.target)">
                <option value="" disabled selected>Select Country</option>
                <option *ngFor="let country of countryList" [value]="country.country_id">{{country.name}}</option>
           </select>
        </div>
        <div class="col-lg-auto my-1 d-none d-md-block">
                <a [routerLink]="['/healing-choose']" [state]="{country_id:activeCountryId}">
                <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
            </a>
        </div>
        <div class="col-lg-auto my-1 text-center d-md-none">
            <a [routerLink]="['/healing-choose']" [state]="{country_id:activeCountryId}">
                <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
            </a>
        </div>
    </div>
</section>

<!-- our services -->
<section style="background: #663399;">
    <div class="container py-5">
        <h3 class="text-center" style="color: white;">Our Services</h3>
        <div class="row">
            <div *ngFor="let service of serviceList" class="col-md-4 py-2">
                <div class="card" style="border-radius: 1rem;">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{service.name}}</h5>
                        <p class="card-text">
                            Healing Time : {{service.time}}<br>
                            Healing charge : {{service.charge | currency:service.charge_currency_code}}
                        </p>
                        <p class="card-text" style="font-weight: 500;">
                            {{service.description}}
                        </p>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>