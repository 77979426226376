import { Component } from '@angular/core';

@Component({
  selector: 'app-page-support',
  templateUrl: './page-support.component.html',
  styleUrl: './page-support.component.scss'
})
export class PageSupportComponent {

}
