import { Component, OnInit } from '@angular/core';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})

// interface Countries {
//   code: string
//   code3: string
//   name: string
//   number: string
// }

export class PageHomeComponent implements OnInit{
  activeCountryId: number = -1;
  
  countryList:{
    country_id: number,
    code: number,
    name: string,
  }[]=[];

  serviceList:{
    service_id: number,
    name: string,
    time: string,
    charge: number,
    charge_currency_code: string,
    description: string,
  }[]=[];

  constructor(private dataService:DataService){}


  ngOnInit(): void {
    this.loadCountryList();
    this.loadServiceList();
  }

  loadCountryList(){
    this.dataService.countryList().subscribe((response:any)=>{
      if(response.status==100){
        this.countryList = response.data;
      }
    });
  }

  onCountryChange(event:any){
    this.activeCountryId = event.value;

  }

  loadServiceList(){
    this.dataService.serviceList().subscribe((response:any)=>{
      if(response.status==100){
        this.serviceList = response.data;
      }
    });
  }

}

