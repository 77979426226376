<section style="padding-top: 7rem;">
    <form action="">
        <div class="container p-4" style="width: 50vw;">
            <div class="vstack p-4" style="border-radius: 1rem; background: #663399;color: white; height: 800px;">
                <h3 class="text-center">Payment Gateway</h3>
                
            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <a [routerLink]="['/healing-confirmation']" class="text-center">
                    <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
                </a>
            </div>
        </div>
    </form>
</section>