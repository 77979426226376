import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-page-healing-choose',
  templateUrl: './page-healing-choose.component.html',
  styleUrls: ['./page-healing-choose.component.scss']
})
export class PageHealingChooseComponent implements OnInit {
  activeCountryId: number = -1;

  serviceList:{
    service_id: number,
    name: string,
    time: string,
    charge: number,
    charge_currency_code: string,
    description: string,
  }[]=[];

  constructor(private router: Router,private dataService:DataService){}

  ngOnInit(): void {
    //use this.location.getState() or history.state
    if (history.state==undefined || history.state.country_id==undefined) {
      this.router.navigate(['/']);
    }else{
      // alert(history.state[0].country_id);
      this.activeCountryId = history.state.country_id;
      this.loadServiceList();
    }
  }

  loadServiceList(){
    this.dataService.serviceList().subscribe((response:any)=>{
      if(response.status==100){
        this.serviceList = response.data;
      }
    });
  }

}
