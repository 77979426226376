import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageHealingChooseComponent } from './page-healing-choose/page-healing-choose.component';
import { PageEmailOtpComponent } from './page-email-otp/page-email-otp.component';
import { PageHealingDetailComponent } from './page-healing-detail/page-healing-detail.component';
import { PageHealingTimeslotComponent } from './page-healing-timeslot/page-healing-timeslot.component';
import { PageProfileDetailComponent } from './page-profile-detail/page-profile-detail.component';
import { PageHealingConfirmationComponent } from './page-healing-confirmation/page-healing-confirmation.component';
import { LayoutComponent } from './layout/layout.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagePaymentComponent } from './page-payment/page-payment.component';
import { PageSuggestionComponent } from './page-suggestion/page-suggestion.component';
import { PageSupportComponent } from './page-support/page-support.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PageHomeComponent,
    PageHealingChooseComponent,
    PageEmailOtpComponent,
    PageHealingDetailComponent,
    PageHealingTimeslotComponent,
    PageProfileDetailComponent,
    PageHealingConfirmationComponent,
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    PagePaymentComponent,
    PageSuggestionComponent,
    PageSupportComponent,
    LayoutSidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
