import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../service-api/data.service';

@Component({
  selector: 'app-page-healing-detail',
  templateUrl: './page-healing-detail.component.html',
  styleUrls: ['./page-healing-detail.component.scss']
})
export class PageHealingDetailComponent implements OnInit {

  healingNo:number = 0;
  healingType:number = 1;

  healingForList:{name:string,selected:boolean}[]=[];

  periodOfDiscomfort:string = '';
  typeOfTreatment:string = '';
  pastTreatment:string = '';

  constructor(private router: Router,private dataService:DataService){}

  ngOnInit(): void {
     if (history.state==undefined || history.state.country_id==undefined || history.state.service_id==undefined) {
      this.router.navigate(['/healing-choose']);
    }else{
      this.loadHealingNo(history.state.country_id,history.state.service_id);
      this.loadHealingforList();
    }
  }


  loadHealingNo(countryId:number,serviceId:number){
    this.dataService.getHealingNo(countryId,serviceId).subscribe((response:any)=>{
      if(response.status==100){
        this.healingNo = response.data.healing_no;
        this.healingType = response.data.healing_type;
      }
    });
  }

  loadHealingforList(){
    this.dataService.healingForList().subscribe((response:any)=>{
      if(response.status==100){
        console.log(this.healingForList = response.data);
        this.healingForList=[];
        response.data.forEach((element: string) => {
          console.log(element)
          this.healingForList.push({name:element,selected:false});
          console.log(this.healingForList)
        });
      }
    });
  }

  toggleHealingFor(healingForName:string){
    this.healingForList.forEach((element:any)=>{
        if(element.name==healingForName){
          element.selected = !element.selected;
        }
    });
  }

  saveHealingType1Detail(){
    var healingFor ='';
    var flag=true;
    this.healingForList.forEach((element:any)=>{
      if(element.selected){
        if(flag){
          healingFor = healingFor + ', ';
          flag=false;
        }
        healingFor = healingFor + element.name;
      }
  });
    this.dataService.updateHealingType1Detail(this.healingNo,healingFor,this.periodOfDiscomfort,this.typeOfTreatment,this.pastTreatment).subscribe((response:any)=>{
      if(response.status==100){
        this.router.navigate(['/healing-choose'],{state:{healing_no:this.healingNo,healing_type:this.healingType}});
      }
    });
  }


}
