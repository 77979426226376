<section style="height: 100vh;">
    <div class="container" style="padding-top: 8rem;">
        <div class="row">
            <div class="col-md-4 mx-auto p-4" style="border-radius: 1rem; background: #663399;">
                <h3 class="text-center" style="color: white;">Contact Us</h3>
                <p class="text-center" style="color: white;">
                </p>
                <div class="vstack gap-3 pt-3">
                     <i class="bi bi-telephone-fill text-center" style="color: white; font-size: large;"> +91 9400 XXXXXX</i> 
                    <a [routerLink]="['/']">
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">Go Back</button>
                    </a>
                </div>
                
            </div>
        </div>
    </div>
</section>