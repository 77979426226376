import { Component } from '@angular/core';

@Component({
  selector: 'app-page-healing-timeslot',
  templateUrl: './page-healing-timeslot.component.html',
  styleUrls: ['./page-healing-timeslot.component.scss']
})
export class PageHealingTimeslotComponent {

}
