
<section style="padding-top: 7rem;">
    <form action="">
        <div class="container p-4" style="border-radius: 1rem; background: #663399;width: 50vw;">
            <h3 class="text-center" style="color: white;">Select Time Slot</h3>
            <div class="hstack pt-2">
                <div class="vstack">
                    <h5 class="text-center" style="color: white;">Day</h5>
                    <div class="py-2 px-4">
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">Today</button>
                    </div>
                    <div class="py-2 px-4">
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">Tomorrow</button>
                    </div>
                    <div class="py-2 px-4">
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">29 Monday</button>
                    </div>
                    <div class="py-2 px-4">
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">30 Tuesday</button>
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">31 Wednesday</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">1 Thursday</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">2 Friday</button>
                        
                    </div>
                </div>
                
                <div class="vstack">
                    <h5 class="text-center" style="color: white;">Time</h5>
                    <div class="py-2 px-4">
                        
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">9AM-10AM</button>
                    
                    </div>
                    <div class="py-2 px-4">
                    
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">10AM-11AM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">11AM-12PM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">12PM-1PM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">1PM-2PM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">2PM-3PM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                            <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">3PM-4PM</button>
                        
                    </div>
                    <div class="py-2 px-4">
                        
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">4PM-5PM</button>
                    
                    </div>
                    <div class="py-2 px-4">
                        
                        <button class="rounded-pill" style="background: #3f205e;color: white;border: 0; height: 3rem; width: 100%;">5PM-6PM</button>
                    
                    </div>
                </div>

            </div>
        </div>

        <div class="container mt-3 mb-5">
            <div class="row">
                <a [routerLink]="['/profile-detail']" class="text-center">
                    <button class="rounded-pill" style="background: #663399;color: white;border: 0; height: 3rem;width: 8rem;">Continue</button>
                </a>
            </div>
        </div>
    </form>
</section>