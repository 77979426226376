import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageEmailOtpComponent } from './page-email-otp/page-email-otp.component';
import { PageHealingChooseComponent } from './page-healing-choose/page-healing-choose.component';
import { PageHealingConfirmationComponent } from './page-healing-confirmation/page-healing-confirmation.component';
import { PageHealingDetailComponent } from './page-healing-detail/page-healing-detail.component';
import { PageHealingTimeslotComponent } from './page-healing-timeslot/page-healing-timeslot.component';
import { PageProfileDetailComponent } from './page-profile-detail/page-profile-detail.component';
import { PageNotfoundComponent } from './page-notfound/page-notfound.component';
import { LayoutComponent } from './layout/layout.component';
import { PagePaymentComponent } from './page-payment/page-payment.component';
import { PageSuggestionComponent } from './page-suggestion/page-suggestion.component';
import { PageSupportComponent } from './page-support/page-support.component';

const routes: Routes = [
 {
  path:'',
  component: LayoutComponent,
  children: [
    {path:'',component:PageHomeComponent,title:'Pranic Healing'},
    {path:'healing-choose',component:PageHealingChooseComponent,title:'Pranic Healing'},
    {path:'healing-confirmation',component:PageHealingConfirmationComponent,title:'Pranic Healing'},
    {path:'healing-detail',component:PageHealingDetailComponent,title:'Pranic Healing'},
    {path:'healing-timeslot',component:PageHealingTimeslotComponent,title:'Pranic Healing'},
    {path:'login',component:PageEmailOtpComponent,title:'Pranic Healing'},
    {path:'profile-detail',component:PageProfileDetailComponent,title:'Pranic Healing'},
    {path:'payment',component:PagePaymentComponent,title:'Pranic Healing'},
    {path:'suggestion',component:PageSuggestionComponent,title:'Pranic Healing'},
    {path:'support',component:PageSupportComponent,title:'Pranic Healing'},
    {path:'**',pathMatch:'full',component:PageNotfoundComponent,title:'Pranic Healing'},
  ]
 }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
